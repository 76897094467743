import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Contact from '../Contact';
import Footer from '../Footer';
import Chatbot from '../ChatBot/chatbot.js';
import SocialShare from './SocialShare.js';

// Styled Components (unchanged)
const PostContainer = styled.div`
  padding: 40px 20px;
  width: 100%;
  margin: 0 auto;
  max-width: 95%;

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 20px;
  }
`;

const FeaturedImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    height: 250px;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const Datee = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_secondary + 80};
  margin-bottom: 10px;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Add spacing between tags */
  margin-bottom: 20px;
  padding: 15px 15px 15px 0; /* Add padding inside the container */
  border-radius: 12px; /* Rounded corners */
`;

const Tag = styled.span`
  background-color: ${({ theme }) => theme.primary + '20'}; /* Slightly transparent background */
  color: ${({ theme }) => theme.primary}; /* Use theme primary color for text */
  padding: 8px 16px; /* Add padding inside tags */
  border-radius: 20px; /* Rounded corners for tags */
  font-size: 14px; /* Adjust font size */
  font-weight: 500; /* Medium font weight */
  transition: all 0.3s ease; /* Smooth transition for hover effects */

  &:hover {
    background-color: ${({ theme }) => theme.primary}; /* Solid background on hover */
    color: white; /* Change text color on hover */
    transform: translateY(-2px); /* Slight lift effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
  }
`;


const Wrapper = styled.div`
  background: linear-gradient(
      38.73deg,
      rgba(204, 0, 187, 0.15) 0%,
      rgba(201, 32, 184, 0) 50%
    ),
    linear-gradient(141.27deg, rgba(0, 70, 209, 0) 50%, rgba(0, 70, 209, 0.15) 100%);
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 98%, 0 100%);
  padding-bottom: 100px;
  @media (max-width: 768px) {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 99%, 0 100%);
  padding-bottom: 100px;
    
  }
`;

const FirstLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  margin-bottom: 30px;
  background-color: ${({ theme }) => theme.card_light};
  padding: 40px;
  border-radius: 8px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
    
  }
`;

const ImageContainer = styled.div`
  width: 40%;
  padding-right: 30px;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
  }
`;

const MetaContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SecondLayout = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px;
  border-radius: 8px;
  min-height: 100vh;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 5px;
  }
`;

const Sidebar = styled.div`
  width: 25%;
  position: sticky;
  top: 120px;
  padding: 40px 20px 40px 40px;
  background-color: ${({ theme }) => theme.card_light};
  align-self: flex-start;
  height: fit-content;
  color: white;
  border-radius: 10px;
  border: 1px solid #854CE6;
  font-size: 14px;
  line-height: 32px;

  a {
    color: white;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  p {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 500;
    color: ${({ theme }) => theme.text_secondary};
  }

  @media (max-width: 768px) {
    width: 100%;
    position: static;
    
  font-size: 16px;
  }
`;

const MainContent = styled.div`
  width: 60%;
  padding: 0 20px;
  align-self: flex-start;
  height: fit-content;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 42px;
  text-align: justify;
  color: ${({ theme }) => theme.text_primary + 95};

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    font-size: 16px;
  }

  ul {
    padding-left:40px;  
  }

  p {
    margin-bottom: 15px;
  }

  h2 {
    font-size: 26px;
    font-weight: 600;
    margin: 20px 0 20px 0;
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
  }

  h4 {
    font-size: 22px;
    font-weight: 700;
  }

  h5 {
    font-size: 20px;
    font-weight: 700;
  }

  h6 {
    font-size: 18px;
    font-weight: 700;
  }
`;

function BlogPost() {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [featuredMedia, setFeaturedMedia] = useState(null);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [acfContent, setAcfContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const mainContentRef = useRef(null); // Ref to the MainContent div

  useEffect(() => {
    fetch(`https://eternitycoders.xyz/blog/wp-json/wp/v2/posts?slug=${slug}&_embed`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Post not found');
        }
        return response.json();
      })
      .then((data) => {
        console.log('API Response:', data);
        if (data.length === 0) {
          throw new Error('Post not found');
        }
        const postData = data[0];
        setPost(postData);
        setFeaturedMedia(postData._embedded?.['wp:featuredmedia']?.[0]?.source_url || null);

        const postId = postData.id;
        Promise.all([
          fetch(`https://eternitycoders.xyz/blog/wp-json/wp/v2/categories?post=${postId}`).then((res) => res.json()),
          fetch(`https://eternitycoders.xyz/blog/wp-json/wp/v2/tags?post=${postId}`).then((res) => res.json()),
        ])
          .then(([categories, tags]) => {
            setCategories(categories);
            setTags(tags);
          })
          .catch((error) => {
            console.error('Error fetching terms:', error);
          });

        setAcfContent(postData.acf?.toc || '');
        setLoading(false);
      })
      .catch((error) => {
        console.error('API Error:', error);
        setError(error.message);
        setLoading(false);
      });
  }, [slug]);

  useEffect(() => {
    if (location.hash && mainContentRef.current) {
      const targetId = location.hash.substring(1);
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - 80; // 100px above
        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [location]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!post) {
    return <p>Post not found.</p>;
  }

  const postUrl = window.location.href;
  const postTitle = post.title.rendered;

  return (
    <>
      <Wrapper>
        <PostContainer>
          <FirstLayout>
            <ImageContainer>
              {featuredMedia && <FeaturedImage src={featuredMedia} alt={post.title.rendered} />}
            </ImageContainer>
            <MetaContainer>
              <Title>{post.title.rendered}</Title>
              <Datee>{new Date(post.date).toLocaleDateString()}</Datee>

              {tags.length > 0 && (
                <TagsContainer>
                  {tags.map((tag) => (
                    <Tag key={tag.id}>{tag.name}</Tag>
                  ))}
                </TagsContainer>
              )}
            </MetaContainer>
          </FirstLayout>
          <SecondLayout>
            <Sidebar>
              <p>Table of Content:</p>
              <div dangerouslySetInnerHTML={{ __html: acfContent }} />
            </Sidebar>
            <MainContent ref={mainContentRef}>
              <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
            </MainContent>
            <SocialShare postUrl={postUrl} title={postTitle} />
          </SecondLayout>
        </PostContainer>
        <Contact />
      </Wrapper>
      <Footer />
      <Chatbot />
    </>
  );
}

export default BlogPost;