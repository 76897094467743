import React from 'react';
import styled from 'styled-components';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton, // Added WhatsApp
} from 'react-share';
import {
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon, // Added WhatsApp
} from 'react-share';

const ShareContainer = styled.div`
  width: 15%;
  position: sticky;
  top: 120px;
  align-self: flex-start;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.card_light + '20'}; /* Slightly transparent background */
  padding: 40px 20px 20px 0px;
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  margin-bottom: 20px;

  @media (max-width: 768px) {
    position: static;
    width: 100%;
    align-items: center;
    padding: 15px;
  }
`;

const ShareText = styled.p`
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.text_secondary};
`;

const SocialIconsContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const IconWrapper = styled.div`
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-3px); /* Slight lift effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
  }
`;

function SocialShare({ postUrl, title }) {
  return (
    <ShareContainer>
      <ShareText>Share this post:</ShareText>
      <SocialIconsContainer>
        <IconWrapper>
          <FacebookShareButton url={postUrl} quote={title}>
            <FacebookIcon size={30} round />
          </FacebookShareButton>
        </IconWrapper>

        <IconWrapper>
          <TwitterShareButton url={postUrl} title={title}>
            <TwitterIcon size={30} round />
          </TwitterShareButton>
        </IconWrapper>

        <IconWrapper>
          <WhatsappShareButton url={postUrl} title={title}>
            <WhatsappIcon size={30} round />
          </WhatsappShareButton>
        </IconWrapper>
      </SocialIconsContainer>
    </ShareContainer>
  );
}

export default SocialShare;