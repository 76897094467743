import { ThemeProvider } from "styled-components";
import { useState } from "react";
import { darkTheme, lightTheme } from './utils/Themes.js';
import Navbar from "./components/Navbar";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import Routes and Route
import HeroSection from "./components/HeroSection";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Experience from "./components/Experience";
import Education from "./components/Education";
import styled from "styled-components";
import Chatbot from './components/ChatBot/chatbot.js'; 
import Blog from './components/Blog'; 
import BlogPost from './components/BlogPost';

// Firebase initialization
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBWlGkFXE8_OYKmoC9tvym7ZQxQ22zAeNU",
  authDomain: "portfolio-c9144.firebaseapp.com",
  projectId: "portfolio-c9144",
  storageBucket: "portfolio-c9144.appspot.com",
  messagingSenderId: "235751047281",
  appId: "1:235751047281:web:6029bcd11ddaf3d2af9bd0",
  measurementId: "G-NXHQ8TMH1D"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
`;

const Wrapper = styled.div`
  background: linear-gradient(38.73deg, rgba(204, 0, 187, 0.15) 0%, rgba(201, 32, 184, 0) 50%), linear-gradient(141.27deg, rgba(0, 70, 209, 0) 50%, rgba(0, 70, 209, 0.15) 100%);
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%,30% 98%, 0 100%);
`;

function App() {
  const [darkMode, setDarkMode] = useState(true);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Router>
        <Navbar />
        <Body>
          <Routes>
            <Route path="/" element={
              <>
                <HeroSection />
                <Wrapper>
                  <Skills />
                  <Experience />
                </Wrapper>
                <Wrapper>
                  <Education />
                  <Contact />
                </Wrapper>
                <Footer />
                <Chatbot />
              </>
            } />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogPost />} /> 
          </Routes>
        </Body>
      </Router>
    </ThemeProvider>
  );
}

export default App;