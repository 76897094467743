import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import Contact from '../Contact';
import Footer from '../Footer';
import Chatbot from '../ChatBot/chatbot.js';

export const HeroContainer = styled.div`
  background: ${({ theme }) => theme.card_light};
  justify-content: center;
  position: relative;
  padding: 150px 30px;
  z-index: 1;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 70% 95%, 0 100%);

  @media (max-width: 960px) {
    padding: 66px 16px;
  }

  @media (max-width: 640px) {
    padding: 90px 16px;
  }
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const BlogContainer = styled.div`
  padding: 20px 0 120px 0;
  text-align: left;
  align-items: center;
  width: 90%;
  margin: 0 auto;
`;

const BlogPostsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 30px;
  justify-content: left;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Desc = styled.div`
  font-size: 14px;
  text-align: left;
  color: ${({ theme }) => theme.text_secondary};

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const Title = styled.div`
  font-size: 22px;
  text-align: left;
  font-weight: 500;
  margin-top: 20px;
  color: #ffffff;

  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 20px;
  }
`;

export const BreadCrumb = styled.div`
  font-size: 50px;
  font-weight: 700;
  color: ${({ theme }) => theme.text_primary};
  line-height: 1.36;

  @media (max-width: 960px) {
    text-align: center;
  }

  @media (max-width: 640px) {
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 8px;
  }
`;

const BlogPost = styled.div`
  width: 31.8%;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  background: linear-gradient(
      38.73deg,
      rgba(204, 0, 187, 0.15) 0%,
      rgba(201, 32, 184, 0) 50%
    ),
    linear-gradient(141.27deg, rgba(0, 70, 209, 0) 50%, rgba(0, 70, 209, 0.15) 100%);
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 98%, 0 100%);
`;

const Datee = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_secondary + 80};

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const FeaturedImage = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 8px;
`;

const ReadMoreButton = styled(Link)`
  display: inline-block;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.primary};
  color: white;
  text-decoration: none;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  width: fit-content;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const skeletonLoading = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

const Skeleton = styled.div`
  background: linear-gradient(90deg, #e0e0e0 25%, #d0d0d0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: ${skeletonLoading} 1.5s linear infinite;
  border-radius: 8px;
`;

const SkeletonImage = styled(Skeleton)`
  width: 100%;
  height: 250px;
  margin-bottom: 10px;
`;

const SkeletonTitle = styled(Skeleton)`
  width: 80%;
  height: 20px;
  margin-bottom: 10px;
`;

const SkeletonDate = styled(Skeleton)`
  width: 50%;
  height: 16px;
  margin-bottom: 10px;
`;

const SkeletonButton = styled(Skeleton)`
  width: 100px;
  height: 30px;
  margin-top: 10px;
`;

function Blog() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://eternitycoders.xyz/blog/wp-json/wp/v2/posts?_embed')
      .then((response) => response.json())
      .then((data) => {
        setPosts(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  return (
    <>
      <HeroContainer>
        <HeroBg>
          <BreadCrumb>Insights</BreadCrumb>
        </HeroBg>
      </HeroContainer>

      <Wrapper>
        <BlogContainer>
          <BlogPostsContainer>
            {loading
              ? Array.from({ length: 3 }).map((_, index) => (
                  <BlogPost key={index}>
                    <SkeletonImage />
                    <SkeletonTitle />
                    <SkeletonDate />
                    <SkeletonButton />
                  </BlogPost>
                ))
              : posts.map((post) => (
                  <BlogPost key={post.slug}>
                    {post._embedded && post._embedded['wp:featuredmedia'] && (
                      <FeaturedImage
                        src={post._embedded['wp:featuredmedia'][0].source_url}
                        alt={post.title.rendered}
                      />
                    )}
                    <Title>{post.title.rendered}</Title>
                    <Datee>{new Date(post.date).toLocaleDateString()}</Datee>
                    <ReadMoreButton to={`/blog/${post.slug}`}>Read Now</ReadMoreButton>
                  </BlogPost>
                ))}
          </BlogPostsContainer>
        </BlogContainer>
        <Contact />
      </Wrapper>
      <Footer />
      <Chatbot />
    </>
  );
}

export default Blog;